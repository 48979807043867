import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import clsx from 'clsx'

import { IconArrow } from 'icons'

const NeedHelpBanner = ({ className }: { className?: string }) => {
  const { t } = useTranslation(['actions'])

  return (
    <div
      className={clsx(
        'mb-10 mt-auto hidden transition-all lg:block xl:w-full xl:pl-0',
        className
      )}
    >
      <Link
        className=" animated-svg group flex w-full cursor-pointer items-end justify-between border-[3px] border-black p-6 transition-colors hover:border-red-550 hover:bg-red-550 hover:text-white active:border-red-550 active:bg-red-550 active:text-white"
        href="/contacts"
      >
        <div>
          <h4 className="mb-1 text-lg font-semibold text-black transition-colors group-hover:text-white group-active:text-white">
            {t('actions:help.title')}
          </h4>
          <p className=" text-gray-700 transition-colors group-hover:text-white group-active:text-white">
            {t('actions:help.description')}
          </p>
        </div>
        <div>
          <IconArrow className="ml-2 h-6 w-6 text-black transition-colors group-hover:text-white group-active:text-white" />
        </div>
      </Link>
    </div>
  )
}

export default NeedHelpBanner
