import { useSession } from 'next-auth/react'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { IRestrictions } from 'data-transfers/dto'

import useRestrictionsStore from 'store/useRestrictionsStore'
import { IUseQueryHook } from 'hooks/types'
import { getRestrictions } from 'hooks/useAuth/auth.service'
import useAuthManager from 'hooks/useAuthManager'

import { UserRole } from 'constants/auth'

import { organisationsKeys } from './keys'

const useRestrictions = ({
  options
}: IUseQueryHook<void, IRestrictions | null, AxiosError>) => {
  const { data: session } = useSession()
  const { signOut } = useAuthManager()
  const setRestrictions = useRestrictionsStore((state) => state.setRestrictions)
  return useQuery(
    organisationsKeys.restrictions(),
    async () => {
      return await getRestrictions()
    },
    {
      keepPreviousData: true,
      refetchInterval: 1000 * 60 * 10, // 10 minutes
      onSuccess: (data) => {
        if (
          session?.user.role === UserRole.OrganisationEmployee &&
          data?.organisation?.subscriptionType !== 'pro'
        ) {
          signOut()
          return
        }
        if (data) {
          setRestrictions(data)
        }
      },
      ...options
    }
  )
}
export default useRestrictions
