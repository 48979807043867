import { useSession } from 'next-auth/react'
import { PropsWithChildren, useCallback } from 'react'

import LoadingWrapper from 'components/LoadingWrapper'
import {
  CandidateNotificationsModal,
  OrganisationNotificationsModal
} from 'components/modals'

import useRestrictions from 'hooks/useOrganisation/useRestrictions'
import { useNotifications } from 'hooks/useProfile'
import useNotificationWasShown from 'hooks/useProfile/useNotificationWasShown'

const CRMRestrictions = ({ children }: PropsWithChildren) => {
  const { status } = useSession()
  const { isInitialLoading } = useRestrictions({
    options: { enabled: status == 'authenticated' }
  })
  const { data: notifications } = useNotifications({
    options: { enabled: status == 'authenticated' }
  })
  const { isLoading, mutate } = useNotificationWasShown()

  const onModalClose = useCallback(() => {
    if (isLoading || !notifications) {
      return
    }
    mutate(notifications)
  }, [isLoading, mutate, notifications])

  return (
    <>
      <LoadingWrapper
        data
        emptyText=""
        isLoading={isInitialLoading || status == 'loading'}
      >
        <>{children}</>
      </LoadingWrapper>

      <CandidateNotificationsModal
        notifications={notifications?.type}
        onClose={onModalClose}
        isLoading={isLoading}
      />
      <OrganisationNotificationsModal
        notifications={notifications?.type}
        onClose={onModalClose}
        isLoading={isLoading}
      />
    </>
  )
}

export default CRMRestrictions
