import clsx from 'clsx'

import { IFlagIconProps } from '../types'

const FlagIcon = ({ lang = 'en', className, style }: IFlagIconProps) => {
  return (
    <span
      className={clsx(`fp fp-md fp-rounded bg-gray-200 ${lang}`, className)}
      style={style}
    />
  )
}

export default FlagIcon
