import Link from 'next/link'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useCallback, useMemo } from 'react'
import clsx from 'clsx'

import { IconArrow } from 'icons'

import useModalStore from 'store/useModalStore'

import { changeRouteEvent } from 'utils/gtm.util'

import { ISideMenuItemProps } from './types'

const SideMenuItem = ({
  link,
  icon: Icon,
  label,
  disabled
}: ISideMenuItemProps) => {
  const router = useRouter()
  const { data } = useSession()

  const onShowProhibitNavigationModal = useModalStore(
    (state) => state.setShowProhibitNavigationModal
  )
  const active = useMemo(() => {
    const path = router.pathname

    return typeof window !== 'undefined'
      ? ((path.includes(link) || '/' === link) &&
          path.replace(link, '') === '') ||
          path.replace(link, '') === '/[id]'
      : false
  }, [link, router.pathname])

  const handleChangeRoute = useCallback(
    (path: string) => () => {
      if (disabled) {
        onShowProhibitNavigationModal(true)
        return
      }
      changeRouteEvent(path, data?.user.role)
    },
    [data?.user.role, disabled, onShowProhibitNavigationModal]
  )

  return (
    <Link
      key={link}
      href={disabled ? '#' : link}
      onClick={handleChangeRoute(link)}
      className={clsx(
        'group my-1 flex items-center justify-start py-3 font-semibold md:pl-0',
        'relative transition-all hover:text-red-550 active:text-red-600'
      )}
    >
      <div
        className={clsx(
          'mx-auto mr-2 h-6 w-6 group-hover:text-red-550 group-active:text-red-600 lg:mx-0 lg:h-5 lg:w-5',
          active && 'text-red-550'
        )}
      >
        <Icon />
      </div>
      <span className="ml-4 hidden flex-1 opacity-0 transition-all lg:block lg:opacity-100">
        {label}
      </span>
      <IconArrow
        className={clsx(
          'fade-in hidden h-6 w-6 text-red-550/0 transition-all group-hover:text-red-550/100 group-active:text-red-600/100 lg:block',
          active && 'text-red-550/100 opacity-100'
        )}
      />
    </Link>
  )
}

export default SideMenuItem
