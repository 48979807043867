import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import { asText } from '@prismicio/helpers'
import { PrismicLink } from '@prismicio/react'

import { Bounded } from 'components/Bounded'
import MenuLanguage from 'components/Menu/MenuLanguage'

import { IHeaderProps } from '../types'

const MenuUser = dynamic(() => import('components/Menu/MenuUser/MenuUser'), {
  ssr: false
})
const MenuMobile = dynamic(
  () => import('components/Menu/MenuMobile/MenuMobile'),
  {
    ssr: false
  }
)

const Header = ({ navigation, settings }: IHeaderProps) => {
  return (
    <Bounded
      as="header"
      className="sticky top-0 z-[110] bg-white px-6 shadow-sm print:hidden"
    >
      <div className="sticky top-0 flex h-[80px] flex-wrap items-center justify-between gap-x-6 leading-none ">
        <div className="flex h-full flex-1 items-center">
          <Link
            href="/"
            className="relative h-12 w-24 transition-all hover:opacity-90 active:opacity-60"
          >
            {settings.data.logo.url && (
              <Image
                src={settings.data.logo.url}
                alt={settings.data.logo.alt ?? '' + ' logo'}
                priority={true}
                style={{ objectFit: 'contain', objectPosition: 'left center' }}
                fill
              />
            )}
          </Link>
        </div>
        <nav className="hidden flex-[3] flex-wrap items-center justify-center gap-6 font-semibold uppercase print:hidden xl:flex xl:gap-10">
          {navigation.data?.links.map((item, index) => (
            <PrismicLink
              key={index}
              field={item.link}
              className="transition-all hover:text-red-600 active:text-red-700"
            >
              <span>{asText(item.label)}</span>
            </PrismicLink>
          ))}
        </nav>
        <div className="hidden flex-1 items-center justify-end print:hidden md:flex">
          <MenuUser />
          <div className="mx-2 h-10 w-[1px] bg-slate-700"></div>
          <MenuLanguage />
        </div>
        <MenuMobile navigation={navigation} />
      </div>
    </Bounded>
  )
}

export default Header
