import { IRestrictions } from 'data-transfers/dto'
import create from 'zustand'

export interface RestrictionsStore extends IRestrictions {
  setRestrictions: (restrictions: IRestrictions) => void
  clear: () => void
}

const useRestrictionsStore = create<RestrictionsStore>()((set) => ({
  candidate: null,
  organisation: null,
  setRestrictions: (restrictions: IRestrictions) =>
    set((state) => ({ ...state, ...restrictions })),

  clear: () =>
    set({
      candidate: null,
      organisation: null
    })
}))

export default useRestrictionsStore
