import Link from 'next/link'
import { PrismicLink } from '@prismicio/react'

import { Bounded } from 'components/Bounded'
import { SocialButton } from 'components/Org/components'
import { IconFacebook2, IconLinkedIn2 } from 'icons'

import { IFooterProps } from '../types'

const Footer = (props: IFooterProps) => {
  return (
    <Bounded
      as="footer"
      className="bg-indigo-1000 px-5 pb-12 text-gray-50 print:hidden lg:pb-12"
    >
      <div className="flex items-center pt-10 pb-8 text-sm md:justify-between lg:pt-12 lg:pb-16">
        <div>
          <div className="hidden bg-indigo-1000">
            {process.env.NEXT_PUBLIC_VERSION}
          </div>
        </div>
        <div>{props.navigationFooter.data.disclaimer}</div>
      </div>
      <div className="flex flex-col-reverse items-center justify-between text-xs lg:flex-row">
        <div className="mt-4 min-w-[200px] py-2 lg:mt-0">
          {props.navigationFooter.data.copyright}
        </div>
        <div className={'mt-4 flex items-start gap-2 lg:mt-0'}>
          <SocialButton
            url={'https://linkedin.com/company/hrizer/'}
            icon={IconLinkedIn2}
            baseUrl={''}
          />
          <SocialButton
            url={'https://www.facebook.com/hrizer.int'}
            icon={IconFacebook2}
            baseUrl={''}
          />
        </div>
        <div className="flex w-full flex-col lg:w-fit lg:flex-row lg:justify-end lg:gap-5">
          {props.navigationFooter.data.links.map((item, index) => (
            <PrismicLink
              key={index}
              field={item.link}
              className="animated-svg ml-0 py-2 pl-0 hover:underline md:pl-2"
            >
              {item.label}
            </PrismicLink>
          ))}
        </div>
      </div>
    </Bounded>
  )
}

export default Footer
